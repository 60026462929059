<template>
  <footer>
    <p>ⓒ 2022 - Emilie Ågren</p>
  </footer>
</template>

<script>

export default {
  name: 'PageFooter',
}
</script>

<style scoped>

footer {
    background-color: rgb(59, 59, 59);
    color: rgb(118, 118, 118);
    text-align: center;
    padding: 2rem;
}

</style>